var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "interactive-soul" }, [
    _c("div", { ref: "logo" }, [
      _c("img", { attrs: { src: require("@/assets/images/souls_logo.png") } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }