








import gsap from 'gsap';
import * as THREE from 'three';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

class GlobalSampleHelper {
  amount: number;
  samples: any[];
  loadedUrls: any[];
  context: AudioContext;

  constructor (context: AudioContext) {
    this.amount = 0;
    this.samples = [];
    this.loadedUrls = [];
    this.context = context;
  }

  async addSample (url: string, arr: any, index: number, name: string) {
    if (this.canAddSample(url)) {
      // console.log("loading sample")
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
      const decodedData = await this.context.decodeAudioData(buffer);
      const obj = {
        sample: decodedData, name: name, index: index
      };
      this.samples.push(obj);

      this.loadedUrls.push(name);
      // this.samples.push(new GlobalSample(src, this.amount));
      this.amount++;
    }
  }

  getSampleByName (name: string) {
    for (let i = 0; i < this.samples.length; i++) {
      const s = this.samples[i];
      if (s.name === name) { return this.samples[i]; }
    }
    return false;
  }

  canAddSample (src: string) {
    for (let i = 0; i < this.samples.length; i++) {
      const s = this.samples[i];
      if (s === src) { return false; }
    }
    return true;
  }
}

class Title {
  mesh: any;
  sadTarget = 1.0;
  angerTarget = 0;
  sadEz = 0;
  angerEz = 0;
  pitchSpikeTarget = 1.0;
  pitchSpike = 1;
  inc = 0;

  constructor (MESH: any) {
    this.mesh = MESH;
    this.sadTarget = 1.0;
    this.angerTarget = 0;
    this.sadEz = 0;
    this.angerEz = 0;
    this.pitchSpikeTarget = 1.0;
    this.pitchSpike = 1;
    this.inc = 0;
  }

  update (CLOCK: any) {
    this.sadEz += (this.sadTarget - this.sadEz) * 0.01;
    this.angerEz += (this.angerTarget - this.angerEz) * 0.01;
    this.inc += CLOCK * 2;

    const shader = this.mesh.material.userData.shader;
    if (shader) {
      shader.uniforms.time.value = this.inc;
      shader.uniforms.curveAmount.value = this.sadEz;
      shader.uniforms.spikeAmount.value = this.angerEz;
      shader.uniforms.spikePitchMult.value = this.pitchSpike * 1;
    }
  }
}

@Component
export default class TitleGL extends Vue {
  @Prop({
    type: Number,
    required: true,
    default: 300
  }) width: number

  @Prop({
    type: Number,
    required: true,
    default: 300
  }) height: number

  @Prop({
    type: String,
    required: true,
    default: ''
  }) glb: string

  @Prop({
    type: Number,
    required: true,
    default: 1.0
  }) scale: number

  silenceTag: any = false;

  mouse: any = {
    pos: new THREE.Vector2(),
    centered: new THREE.Vector2(),
    tilt: new THREE.Vector2(),
    lastPosition: new THREE.Vector2(),
    delta: new THREE.Vector2(),
    deltaDown: new THREE.Vector2(),
    downPosition: new THREE.Vector2(),
    pointer: new THREE.Vector2(),
    pointerLocked: false,
    down: false,
    firstMoveVertical: false,
    moveOT: false,
    raytrace: new THREE.Vector2()
  }

  context: any // audio context
  raycaster: any = new THREE.Raycaster()
  camera: any
  scene: any
  renderer: any
  composer: any
  bloomPass: any
  controls: any
  clock: any = new THREE.Clock();
  params: any = {
    exposure: 1.05,
    bloomStrength: 0.0,
    bloomThreshold: 0,
    bloomRadius: 0.0
  };

  globalSamples: any;
  meshes: any[] = [];

  emotionSamples: any[]
  emotionSongUrls = [
    'SOULS_JB1',
    'SOULS_JB2'
  ]

  songIndex = 0;

  async mounted () : Promise<void> {
    const container: Element = this.$refs.threedholder as Element;

    this.camera = new THREE.PerspectiveCamera(65, this.width / this.height, 0.25, 20);
    this.camera.position.set(0, 0, 3);
    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      antialias: true, alpha: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    container.appendChild(this.renderer.domElement);
    this.renderer.domElement.id = 'threed';

    // don't allow highlighting of the canvas, aka triple clicking to select all
    this.renderer.domElement.onselectstart = () => { return false; };

    const dirLight = new THREE.DirectionalLight(0xffffff);
    dirLight.position.set(-3, 10, -10);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 2;
    dirLight.shadow.camera.bottom = -2;
    dirLight.shadow.camera.left = -2;
    dirLight.shadow.camera.right = 2;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 40;

    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    this.scene.environment = pmremGenerator.fromScene(
      new RoomEnvironment(),
      0.04
    ).texture;

    this.renderer.domElement.addEventListener('mousedown', this.onMouseDown);
    this.renderer.domElement.addEventListener('mousemove', this.onMouseMove);
    this.renderer.domElement.addEventListener('touchstart', this.onTouchStart);
    this.renderer.domElement.addEventListener('touchend', this.onTouchEnd);
    this.renderer.domElement.addEventListener('touchcancel', this.onTouchEnd);

    window.addEventListener('resize', this.onWindowResize);

    // load gltf objects
    this.initLoadingObjects();

    if (!this.context) {
      // create audio context to play sounds
      this.context = new window.AudioContext();
      this.globalSamples = new GlobalSampleHelper(this.context);
      // load audio samples into memory
      await this.loadAudio();
    }

    // then call animate recursively
    this.animate();
  }

  animate () : void {
    // setup the next call recursively
    window.requestAnimationFrame(this.animate);

    const d = this.clock.getDelta();

    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].update(d);
    }

    this.renderer.render(this.scene, this.camera);
  }

  async initTilt () : Promise<void> {
    const mobEl = document.getElementById('mobile-orientation-init');
    if (mobEl) { mobEl.style.display = 'none'; }

    if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
      // iOS 13+ device requires granting permission
      try {
        const permissionState = await (DeviceOrientationEvent as any).requestPermission();
        if (permissionState === 'granted') {
          window.addEventListener('deviceorientation', () => {
            const centerX = document.getElementById('center-x');
            const centerY = document.getElementById('center-y');

            if (centerX && centerY) {
              centerX.innerHTML = 'INITED' + this.mouse.centered.x;
              centerY.innerHTML = 'INITED' + this.mouse.centered.y;
            }
          });
        }
      } catch (e: any) {
        console.error(e);
      }
    } else {
      // iOS 12 or below device does not require granting permission
      window.addEventListener('deviceorientation', () => {
        const centerX = document.getElementById('center-x');
        const centerY = document.getElementById('center-y');
        if (centerX && centerY) {
          centerX.innerHTML = 'INITED' + this.mouse.centered.x;
          centerY.innerHTML = 'INITED' + this.mouse.centered.y;
        }
      });
    }
  }

  clamp (num: number, min: number, max: number) : number {
    return Math.min(Math.max(num, min), max);
  }

  onTouchEnd () : void {
    this.mouse.down = false;
  }

  @Emit('show-tab')
  async onMouseDown () : Promise<number> {
    // create audio context on first interaction
    this.dispatchAudio();

    this.onInteraction();

    // return the home tab's index
    return 0;
  }

  playSilenceAsMedia () : void {
    if (!this.silenceTag) {
      const silenceDataURL = 'data:audio/mp3;base64,//MkxAAHiAICWABElBeKPL/RANb2w+yiT1g/gTok//lP/W/l3h8QO/OCdCqCW2Cw//MkxAQHkAIWUAhEmAQXWUOFW2dxPu//9mr60ElY5sseQ+xxesmHKtZr7bsqqX2L//MkxAgFwAYiQAhEAC2hq22d3///9FTV6tA36JdgBJoOGgc+7qvqej5Zu7/7uI9l//MkxBQHAAYi8AhEAO193vt9KGOq+6qcT7hhfN5FTInmwk8RkqKImTM55pRQHQSq//MkxBsGkgoIAABHhTACIJLf99nVI///yuW1uBqWfEu7CgNPWGpUadBmZ////4sL//MkxCMHMAH9iABEmAsKioqKigsLCwtVTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVV//MkxCkECAUYCAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
      this.silenceTag = document.createElement('audio');
      this.silenceTag.controls = false;
      this.silenceTag.preload = 'auto';
      this.silenceTag.loop = false;
      this.silenceTag.src = silenceDataURL;
    }

    // by playing silence, we trick iOS into letting the webaudio play while
    this.silenceTag.play();
  }

  @Emit('show-tab')
  async onTouchStart (event: TouchEvent) : Promise<number> {
    event.preventDefault();

    // create equivalent mouse event from touch event
    this.mouse.down = true;
    const touch = event.touches[0];
    this.mouse.pos.x = touch.clientX;
    this.mouse.pos.y = touch.clientY;

    const realTarget = document.elementFromPoint(touch.clientX, touch.clientY);
    if (realTarget) {
      this.mouse.pos.x = touch.pageX - realTarget.getBoundingClientRect().x;
      this.mouse.pos.y = touch.pageY - realTarget.getBoundingClientRect().y;
    }

    if (!this.context) {
      // create audio context to play sounds
      this.context = new window.AudioContext();
      this.globalSamples = new GlobalSampleHelper(this.context);
      // load audio samples into memory
      await this.loadAudio();
    }

    this.playSilenceAsMedia();
    this.dispatchAudio();

    this.onInteraction();

    return 0;
  }

  onMouseMove (e: MouseEvent): void {
    this.mouse.pos.x = e.offsetX;
    this.mouse.pos.y = e.offsetY;
    this.mouse.centered.x = (e.pageX - window.innerWidth / 2) / (window.innerWidth / 2);
    this.mouse.centered.y = (e.pageY - window.innerHeight / 2) / (window.innerHeight / 2);
  }

  onWindowResize () : void {
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.width, this.height);
  }

  onInteraction (): void {
    const mesh = this.checkForRaycastIntersection(this.mouse.pos.x, this.mouse.pos.y);

    // check if we hit anything
    if (mesh) {
      for (let i = 0; i < this.meshes.length; i++) {
        const firstAnimationDuration = 0.2;
        gsap.to(this.meshes[i], {
          duration: firstAnimationDuration,
          pitchSpike: 4,
          ease: 'circ.out()'
        });
        gsap.to(this.meshes[i], {
          duration: 0.2,
          pitchSpike: 0,
          ease: 'circ.out()',
          delay: firstAnimationDuration
        });
      }
    }
  }

  builDisplaceMaterial (rippleSize: number, rippleAmount: number, normalMult: number) : THREE.MeshStandardMaterial {
    const material = new THREE.MeshStandardMaterial();
    material.onBeforeCompile = (shader) => {
      shader.uniforms.time = {
        value: 0
      };
      shader.uniforms.rippleSize = {
        value: rippleSize
      };
      shader.uniforms.rippleAmount = {
        value: rippleAmount
      };
      shader.uniforms.curveAmount = {
        value: 1
      };
      shader.uniforms.spikeAmount = {
        value: 0
      };
      shader.uniforms.normalMult = {
        value: normalMult
      };
      shader.uniforms.spikePitchMult = {
        value: 1
      };

      shader.vertexShader =
        'uniform float time;\n' +
        'uniform float rippleSize;\n' +
        'uniform float rippleAmount;\n' +
        'uniform float curveAmount;\n' +
        'uniform float spikeAmount;\n' +
        'uniform float spikePitchMult;\n' +
        'uniform float normalMult;\n' + shader.vertexShader;

      shader.vertexShader = shader.vertexShader.replace(
        '#include <begin_vertex>',
        [

          'float freqX = position.y * rippleSize;',
          'float freqY = position.x * rippleSize;',
          'float thetay = sin( time*.5 + freqY )  * (rippleAmount*spikePitchMult);',
          'float thetax = cos( time*.5 + freqX )  * (rippleAmount*spikePitchMult);',

          'float a = 0.05;',
          'float b = pow(a, 1.0/a);',
          'float freqSpikeX = position.y * (rippleAmount*50.0) ;',
          'float freqSpikeY = position.x * (rippleAmount*50.0);',
          'float m = 1.1;',
          'float ix = time*.1 + freqSpikeX;',
          'float iy = time*.1 + freqSpikeY;',
          'float lineary = m-abs(mod(iy, 2.0*m) - m ) ;',
          'float linearx = m-abs(mod(ix, 2.0*m) - m ) ;',
          'float spikey = a * -pow(b, lineary)*(1.0*spikePitchMult);',
          'float spikex = a * -pow(b, linearx)*(1.0*spikePitchMult);',

          'vec3 view_space_normal = vec3(projectionMatrix  * modelViewMatrix  * vec4(vNormal, 0.0));',
          'vec3 n = vNormal;',
          'float curve = (thetax + thetay)*curveAmount;',
          'float spike = (spikex + spikey)*spikeAmount;',

          'vec3 transformed = vec3( position + ( (view_space_normal*normalMult) * (curve+spike) ));'
          // 'vNormal = vNormal * m;'
        ].join('\n')
      );

      material.userData.shader = shader;
    };

    return material;
  }

  initLoadingObjects () : void {
    const loader = new GLTFLoader().setPath('');
    loader.load(this.glb, (gltf) => {
      const rs = 10;
      const ra = 0.01;

      gltf.scene.traverse((child: any) => {
        if (child.isMesh) {
          const emissive = child.material.emissive.clone();
          const map = child.material.emissiveMap;
          const roughness = child.material.roughness;
          child.scale.set(this.scale, this.scale, this.scale);

          const raFinal = ra;
          const rsFinal = rs;
          const nrmlMult = 1;

          child.material = this.builDisplaceMaterial(rsFinal, raFinal, nrmlMult);
          child.material.envMap = this.scene.environment;
          child.material.envMapIntensity = 1;
          child.material.roughness = roughness;
          child.material.emissive = emissive;
          child.material.emissiveMap = map;

          child.material.color = new THREE.Color(0x000000);

          const s = child.scale.x;
          child.scale.set(0.0, 0.0, 0.0);
          gsap.to(child.scale, {
            duration: 5.2,
            x: s,
            y: s,
            z: s,
            ease: 'back.out(1.7)'
          });

          this.meshes.push(new Title(child));
        }
      });

      // console.log(this.meshes.length);

      this.scene.add(gltf.scene);
    });
  }

  checkForRaycastIntersection (x: number, y: number) : null|THREE.Mesh {
    for (let i = 0; i < this.meshes.length; i++) {
      this.mouse.raytrace.x = (x / this.width) * 2 - 1;
      this.mouse.raytrace.y = -(y / this.height) * 2 + 1;
      this.raycaster.setFromCamera(this.mouse.raytrace, this.camera);

      // See if the ray from the camera into the world hits one of our meshes
      const intersects = this.raycaster.intersectObject(this.meshes[i].mesh);
      if (intersects.length > 0) {
        return this.meshes[i];
      }
    }

    return null;
  }

  getKey (shouldDoRandom: boolean) : number {
    const start = 60;
    const fnl = start + (-2 + Math.random() * 4);
    if (shouldDoRandom) {
      return fnl;
    } else {
      return start;
    }
  }

  async loadAudio () : Promise<void> {
    for (let t = 0; t < this.emotionSongUrls.length; t++) {
      const index = t;
      const name = this.emotionSongUrls[t];
      await this.globalSamples.addSample(name + '.mp3', this.globalSamples.samples, index, name, this.context);
    }
  }

  dispatchAudio () : void {
    this.songIndex++;
    this.songIndex = this.songIndex % this.globalSamples.samples.length;

    this.playSampleInArray(
      this.getKey(false),
      this.globalSamples.samples,
      this.songIndex,
      0.5
    );
  }

  playSampleInArray (note: number, arr: any[], index: number, dist: number) : void {
    if (arr[index] != null) {
      const source = this.context.createBufferSource();
      source.buffer = arr[index].sample;
      const gainNode = this.context.createGain();
      gainNode.gain.value = dist; // 10 %
      source.playbackRate.value = 2 ** ((note - 60) / 12);
      source.connect(gainNode);
      gainNode.connect(this.context.destination);
      source.start(0);
    }
  }
}
