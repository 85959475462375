var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-project" },
    [
      _c("AppHeader", { on: { "show-tab": _vm.changeTab } }),
      _c("div", { attrs: { id: "souls-holder" } }),
      _c(
        "div",
        { attrs: { id: "home" } },
        [
          _vm.showTab === 0 ? _c("Hero") : _vm._e(),
          _vm.showTab === 0 ? _c("Content") : _vm._e(),
          _vm.showTab === 1 ? _c("FAQ") : _vm._e(),
          _vm._m(0),
          _c("div", { staticClass: "manifold-logo" }, [
            _c(
              "a",
              {
                attrs: {
                  href: "https://twitter.com/manifoldxyz",
                  target: "_blank",
                },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "36",
                      height: "19",
                      viewBox: "0 0 36 19",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M5.78257 6.43467L0 0.6521H31.5655L36 5.6463L26.0686 15.5777H24.0151L26.7857 18.3483H17.7221L9.20754 9.83369H18.2712L20.1402 11.7028L25.4083 6.43467H5.78257Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _vm._m(1),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "exit-cta" }, [
        _vm._v(
          " Souls are the beginning of a great adventure in creativity & community. Join us! "
        ),
      ]),
      _c("div", { staticClass: "exit-links" }, [
        _c(
          "a",
          {
            staticClass: "exit-link",
            attrs: { href: "https://discord.gg/siaverse", target: "_blank" },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/discord-white.svg") },
            }),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "exit-link",
            attrs: {
              href: "https://opensea.io/collection/souls",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/opensea-white.svg") },
            }),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "exit-link",
            attrs: {
              href: "https://twitter.com/souls_galaxy",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/twitter-white.svg") },
            }),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "10px" } }, [
      _vm._v(" Powered by "),
      _c(
        "a",
        {
          attrs: { href: "https://twitter.com/manifoldxyz", target: "_blank" },
        },
        [_vm._v(" Manifold ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }