






























































































































































































import { Component, Vue } from 'vue-property-decorator';
import Accordion from '@/components/Home/Accordion.vue';

@Component({
  components: {
    Accordion
  }
})
export default class FAQ extends Vue {}
