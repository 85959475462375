


































































import jQuery from 'jquery';
import { Component, Vue } from 'vue-property-decorator';
import AppHeader from '@/components/AppHeader.vue';
import Content from '@/components/Home/Content.vue';
import FAQ from '@/components/Home/FAQ.vue';
import Hero from '@/components/Home/Hero.vue';
import { isMobile } from '@/lib/functions';
import store from '@/store/store';
import Perlin from '@/vendor/Perlin';

class ScrollObject {
  perlinNoise: any = new Perlin('random seed');
  y: number;
  x: number;
  xRnd: number;
  rndImg: number;
  obj: any; // jquery object
  scl: number;
  rot: number;
  rotSpeed: number;
  s: number;
  stopScalingUpObjects: boolean;
  mtTarg: number;
  mlTarg: number;
  mt: number;
  ml: number;
  px: number;
  py: number
  pxSpeed: number;
  pySpeed: number;
  constructor (YPOS: number, ORD: number, patchedjQuery: any) {
    // get our y and random x
    this.xRnd = Math.random();
    this.y = YPOS;
    this.x = this.xRnd * (window.innerWidth - 10);
    this.scl = 0.1 + Math.random() * 0.2;
    this.rot = Math.random() * 360;
    this.rndImg = Math.floor(Math.random() * 170);
    const backgroundSoulsBaseURI = '/t2/';
    // append the soul to the DOM
    patchedjQuery('#souls-holder')
      .append(
        "<div class='soul' id='fi" +
        ORD +
        "'><img src='" +
        backgroundSoulsBaseURI +
        this.rndImg +
        ".png'/></div>"
      );
    // grab the reference
    this.obj = patchedjQuery('#fi' + ORD + '');
    // place it at the random x and y value and rotate it
    this.obj.css('left', this.x + 'px');
    this.obj.css('top', this.y + 'px');
    this.obj.rotate('' + (this.rot));
    this.s = 0;
    this.mtTarg = 0.0;
    this.mt = 0;
    this.mlTarg = 0;
    this.ml = 0;
    this.px = 0;
    this.py = 0;
    this.pxSpeed = (-1 + Math.random() * 2) * 0.2;
    this.pySpeed = (-1 + Math.random() * 2) * 0.2;
    this.rotSpeed = -0.2 + Math.random() * 0.4;
    this.stopScalingUpObjects = false;
    // scale them up from 0 to X over 600ms
    setTimeout(() => {
      this.stopScalingUpObjects = true;
    }, Math.random() * 600);
  }

  update = (currScroll: number, prevScroll: number) => {
    if (this.stopScalingUpObjects) {
      this.s += (this.scl - this.s) * 0.01;
    } else {
      this.s = 0;
    }
    this.obj.scale('' + (this.s));
    this.rot += this.rotSpeed * 2.1;
    this.obj.rotate('' + (this.rot));
    this.px += this.pxSpeed * 0.04;
    this.py += this.pySpeed * 0.04;
    const vx = this.perlinNoise.noise(this.px, 0, 0);
    const vy = this.perlinNoise.noise(0, this.py, 0);
    this.mtTarg += ((((currScroll - prevScroll)) *
      ((0.3 - this.scl) * 16.0))) *
      0.3; // dampening
    this.mlTarg = vx * 100;
    this.mt += ((this.mtTarg + (vy * 100)) - this.mt) * 0.01;
    this.ml += (this.mlTarg - this.ml) * 0.01;
    this.obj.css('margin-top', this.mt + 'px');
    this.obj.css('margin-left', this.ml + 'px');
    this.windowResize();
  }

  windowResize = () => {
    // new xRnd using new innerWidth of window
    this.x = this.xRnd * (window.innerWidth - 10);
    // moves soul according to that new window boundary
    this.obj.css('left', this.x + 'px');
  }
}
@Component({
  components: {
    Hero,
    Content,
    FAQ,
    AppHeader
  }
})
export default class Home extends Vue {
  patchedjQuery: any = {};
  so: ScrollObject[] = []
  showTab = 0;
  totalSouls = 250;
  currScroll = 0;
  prevScroll = 0;
  appEl: HTMLElement|null;
  mounted () : void {
    this.patchedjQuery = this.patchjQuery(jQuery);
    this.appEl = document.querySelector('#app');
    this.initObjects();
    this.currScroll = this.prevScroll = window.scrollY;
    this.updateScroll();
  }

  changeTab (index: number) : void {
    this.showTab = index;
  }

  initObjects () : void {
    const height = document.body.clientHeight;
    let amt = this.totalSouls;
    if (isMobile) {
      amt = amt * 0.2;
    }
    for (let i = 0; i < amt; i++) {
      const yPos = Math.random() * (i * (height / amt));
      const s = new ScrollObject(yPos, i, this.patchedjQuery);
      s.update(0, 0);
      this.so.push(s);
    }
  }

  updateScroll (_time?: number) : void {
    if (this.appEl) {
      this.currScroll = this.appEl.scrollTop;
    }
    for (let i = 0; i < this.so.length; i++) {
      this.so[i].update(this.currScroll, this.prevScroll);
    }
    this.prevScroll = this.currScroll;
    window.requestAnimationFrame(this.updateScroll);
  }

  // this adds jquery rotate and transform, which is not available cleanly
  // in the typescript + npm + vue environment
  patchjQuery ($: any|typeof jQuery) : any {
    function initData ($el: any) {
      let _ARS_data = $el.data('_ARS_data');
      if (!_ARS_data) {
        _ARS_data = {
          rotateUnits: 'deg',
          scale: 1,
          rotate: 0
        };
        $el.data('_ARS_data', _ARS_data);
      }
      return _ARS_data;
    }
    function setTransform ($el: any, data: any) {
      $el.css('transform', 'rotate(' + data.rotate + data.rotateUnits + ') scale(' + data.scale + ',' + data.scale + ')');
    }
    $.fn.rotate = function (val: any) {
      const $self = $(this);
      const data = initData($self);
      if (typeof val === 'undefined') {
        return data.rotate + data.rotateUnits;
      }
      const m = val.toString().match(/^(-?\d+(\.\d+)?)(.+)?$/);
      if (m) {
        if (m[3]) {
          data.rotateUnits = m[3];
        }
        data.rotate = m[1];
        setTransform($self, data);
      }
      return this;
    };
    // Note that scale is unitless.
    $.fn.scale = function (val: any) {
      const $self = $(this); const data = initData($self);
      if (typeof val === 'undefined') {
        return data.scale;
      }
      data.scale = val;
      setTransform($self, data);
      return this;
    };
    // fx.cur() must be monkey patched because otherwise it would always
    // return 0 for current rotate and scale values
    const curProxied = $.fx.prototype.cur;
    $.fx.prototype.cur = function () {
      if (this.prop === 'rotate') {
        return parseFloat($(this.elem).rotate());
      } else if (this.prop === 'scale') {
        return parseFloat($(this.elem).scale());
      }
      return curProxied.apply(this, arguments);
    };
    $.fx.step.rotate = function (fx: any) {
      const data = initData($(fx.elem));
      $(fx.elem).rotate(fx.now + data.rotateUnits);
    };
    $.fx.step.scale = function (fx: any) {
      $(fx.elem).scale(fx.now);
    };
    const animateProxied = $.fn.animate;
    $.fn.animate = function (prop: any) {
      if (typeof prop.rotate !== 'undefined') {
        let $self; let data; const m = prop.rotate.toString().match(/^(([+-]=)?(-?\d+(\.\d+)?))(.+)?$/);
        if (m && m[5]) {
          $self = $(this);
          data = initData($self);
          data.rotateUnits = m[5];
        }
        prop.rotate = m[1];
      }
      return animateProxied.apply(this, arguments);
    };
    return $;
  }
}
