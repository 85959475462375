









import gsap from 'gsap';
import * as THREE from 'three';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

class GlobalSampleHelper {
  amount: number;
  samples: any[];
  loadedUrls: any[];
  context: AudioContext;

  constructor (context: AudioContext) {
    this.amount = 0;
    this.samples = [];
    this.loadedUrls = [];
    this.context = context;
  }

  async addSample (url: string, arr: any, index: number, name: string) {
    if (this.canAddSample(url)) {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
      const decodedData = await this.context.decodeAudioData(buffer);
      const obj = {
        sample: decodedData, name: name, index: index
      };
      this.samples.push(obj);

      this.loadedUrls.push(name);
      this.amount++;
    }
  }

  getSampleByName (name: string) {
    for (let i = 0; i < this.samples.length; i++) {
      const s = this.samples[i];
      if (s.name === name) { return this.samples[i]; }
    }
    return false;
  }

  canAddSample (src: string) {
    for (let i = 0; i < this.samples.length; i++) {
      const s = this.samples[i];
      if (s === src) { return false; }
    }
    return true;
  }
}

@Component
export default class TitleGL extends Vue {
  silenceTag: any = false;
  mouse: any = {
    down: false
  }

  context: any // audio context
  globalSamples: any;

  emotionSamples: any[]
  emotionSongUrls = [
    'SOULS_JB1',
    'SOULS_JB2'
  ]

  songIndex = 0;

  async mounted () : Promise<void> {
    (this.$refs.logo as HTMLElement).addEventListener('mousedown', this.onMouseDown);
    (this.$refs.logo as HTMLElement).addEventListener('touchstart', this.onTouchStart);
    (this.$refs.logo as HTMLElement).addEventListener('touchend', this.onTouchEnd);
    (this.$refs.logo as HTMLElement).addEventListener('touchcancel', this.onTouchEnd);

    if (!this.context) {
      // create audio context to play sounds
      this.context = new window.AudioContext();
      this.globalSamples = new GlobalSampleHelper(this.context);
      // load audio samples into memory
      await this.loadAudio();
    }
  }

  onTouchEnd () : void {
    this.mouse.down = false;
  }

  onMouseDown () : void {
    // create audio context on first interaction
    this.dispatchAudio();
  }

  playSilenceAsMedia () : void {
    if (!this.silenceTag) {
      const silenceDataURL = 'data:audio/mp3;base64,//MkxAAHiAICWABElBeKPL/RANb2w+yiT1g/gTok//lP/W/l3h8QO/OCdCqCW2Cw//MkxAQHkAIWUAhEmAQXWUOFW2dxPu//9mr60ElY5sseQ+xxesmHKtZr7bsqqX2L//MkxAgFwAYiQAhEAC2hq22d3///9FTV6tA36JdgBJoOGgc+7qvqej5Zu7/7uI9l//MkxBQHAAYi8AhEAO193vt9KGOq+6qcT7hhfN5FTInmwk8RkqKImTM55pRQHQSq//MkxBsGkgoIAABHhTACIJLf99nVI///yuW1uBqWfEu7CgNPWGpUadBmZ////4sL//MkxCMHMAH9iABEmAsKioqKigsLCwtVTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVV//MkxCkECAUYCAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
      this.silenceTag = document.createElement('audio');
      this.silenceTag.controls = false;
      this.silenceTag.preload = 'auto';
      this.silenceTag.loop = false;
      this.silenceTag.src = silenceDataURL;
    }

    // by playing silence, we trick iOS into letting the webaudio play while
    this.silenceTag.play();
  }

  async onTouchStart (event: TouchEvent) : Promise<void> {
    event.preventDefault();

    // create equivalent mouse event from touch event
    this.mouse.down = true;

    if (!this.context) {
      // create audio context to play sounds
      this.context = new window.AudioContext();
      this.globalSamples = new GlobalSampleHelper(this.context);
      // load audio samples into memory
      await this.loadAudio();
    }

    this.playSilenceAsMedia();
    this.dispatchAudio();

    // this.onInteraction();
  }

  getKey (shouldDoRandom: boolean) : number {
    const start = 60;
    const fnl = start + (-2 + Math.random() * 4);
    if (shouldDoRandom) {
      return fnl;
    } else {
      return start;
    }
  }

  async loadAudio () : Promise<void> {
    for (let t = 0; t < this.emotionSongUrls.length; t++) {
      const index = t;
      const name = this.emotionSongUrls[t];
      await this.globalSamples.addSample(name + '.mp3', this.globalSamples.samples, index, name, this.context);
    }
  }

  dispatchAudio () : void {
    this.songIndex++;
    this.songIndex = this.songIndex % this.globalSamples.samples.length;

    this.playSampleInArray(
      this.getKey(false),
      this.globalSamples.samples,
      this.songIndex,
      0.5
    );
  }

  playSampleInArray (note: number, arr: any[], index: number, dist: number) : void {
    if (arr[index] != null) {
      const source = this.context.createBufferSource();
      source.buffer = arr[index].sample;
      const gainNode = this.context.createGain();
      gainNode.gain.value = dist; // 10 %
      source.playbackRate.value = 2 ** ((note - 60) / 12);
      source.connect(gainNode);
      gainNode.connect(this.context.destination);
      source.start(0);
    }
  }
}
