






































































import { Component, Emit, Vue } from 'vue-property-decorator';
import TitleGL from '@/components/Home/TitleGL.vue';
import TitleHTML from '@/components/Home/TitleHTML.vue';
import { FALLBACK_PROVIDER, NETWORK } from '@/constants';
import { Module1Module } from '@/store/modules/module1';

@Component({
  components: {
    TitleGL,
    TitleHTML
  }
})
export default class AppHeader extends Vue {
  mobileOpen = false
  titleGLWidth = 200
  titleGLHeight = 80
  network = NETWORK
  fallbackProvider = FALLBACK_PROVIDER
  isMobile = false;

  created () : void {
    window.addEventListener('resize', this.onResize);
    // resize the title-g-l element
    if (window.innerWidth <= 1024) {
      this.isMobile = true;
      this.titleGLHeight = 60;
    } else {
      this.isMobile = false;
      this.titleGLHeight = 80;
    }
  }

  destroyed () : void {
    window.removeEventListener('resize', this.onResize);
  }

  onResize (event: Event) : void {
    const target = event.target as Window;

    // resize the title-g-l element
    if (target.innerWidth <= 1024) {
      this.isMobile = true;
      this.titleGLHeight = 60;
    } else {
      this.isMobile = false;
      this.titleGLHeight = 80;
    }
  }

  toggleMobile () : void {
    this.mobileOpen = !this.mobileOpen;
  }

  @Emit('show-tab')
  showTab (index: number) : number {
    this.mobileOpen = false;
    return index;
  }

  async viewYourSoul () : Promise<void> {
    if (this.$store.state.module1.walletAddress) {
      this.mobileOpen = false;
      // @ts-ignore
      const tokens = await this.$store.state.module1.client.getNFTsOfOwner({
        filters: [
          {
            contractAddress: '0x477F516db22683e65Df58C2cBA4f5D5859d48424'
          }
        ]
      });

      if (tokens.length) {
        const tokenIds: string[] = [];
        tokens.forEach((token: any) => {
          tokenIds.push(token.metadata.name.split('#')[1]);
        });

        let tokenString = '?s=';
        tokenIds.forEach((token: string, index: number) => {
          tokenString += token;

          if (index !== tokenIds.length - 1) {
            tokenString += '&s=';
          }
        });

        // @ts-ignore
        window.handleEmojiClick(tokenString, tokenIds.length);
      }
    } else {
      // @ts-ignore
      window.handleEmojiClick('?rnd=30');
    }
  }
}
