var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "accordion-button",
        attrs: {
          "aria-expanded": _vm.isOpen,
          "aria-controls": "collapse" + _vm._uid,
        },
        on: {
          click: function ($event) {
            return _vm.toggleAccordion()
          },
        },
      },
      [
        _vm._t("title"),
        _c(
          "svg",
          {
            staticClass: "w-3 transition-all duration-200 transform",
            class: {
              "rotate-180": _vm.isOpen,
              "rotate-0": !_vm.isOpen,
            },
            attrs: {
              fill: "none",
              stroke: _vm.color,
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 16 10",
              "aria-hidden": "true",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M15 1.2l-7 7-7-7",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        ),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
        attrs: { id: "collapse" + _vm._uid },
      },
      [_vm._t("content")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }