
























import SimplexNoise from 'simplex-noise';
import { Component, Prop, Vue } from 'vue-property-decorator';
import spline from '@/vendor/spline';

@Component
export default class Blob extends Vue {
  path: any;
  hueNoiseOffset: number;
  noiseStep: number;
  simplex: any;
  points: any[];

  @Prop({
    type: String,
    required: true,
    default: 'Text'
  }) text: string

  mounted () : void {
    this.path = this.$refs.path;
    // used to set our custom property values

    this.hueNoiseOffset = 0;
    this.noiseStep = 0.001;

    this.simplex = new SimplexNoise();
    this.points = this.createPoints();
    this.animate();
  }

  createPoints () : any[] {
    const points : any[] = [];
    // how many points do we need
    const numPoints = 8;
    // used to equally space each point around the circle
    const angleStep = (Math.PI * 2) / numPoints;
    // the radius of the circle
    const rad = 75;

    for (let i = 1; i <= numPoints; i++) {
      // x & y coordinates of the current point
      const theta = i * angleStep;

      const x = 100 + Math.cos(theta) * rad;
      const y = 100 + Math.sin(theta) * rad;

      const newPoint = {
        x: x,
        y: y,
        // we need to keep a reference to the point's original point for when we modulate the values later
        originX: x,
        originY: y,
        noiseOffsetX: Math.random() * 1000,
        noiseOffsetY: Math.random() * 1000
      };
      // store the point's position
      points.push(newPoint);
    }

    return points;
  }

  map (n: number, start1: number, end1: number, start2: number, end2: number) : number {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
  }

  noise (x: number, y: number) : any {
    return this.simplex.noise2D(x, y);
  }

  animate () : void {
    this.path.setAttribute('d', spline(this.points, 1, true));

    // for every point...
    for (let i = 0; i < this.points.length; i++) {
      const point = this.points[i];

      // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
      const nX = this.noise(point.noiseOffsetX, point.noiseOffsetX);
      const nY = this.noise(point.noiseOffsetY, point.noiseOffsetY);
      // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
      const x = this.map(nX, -1, 1, point.originX - 8, point.originX + 8);
      const y = this.map(nY, -1, 1, point.originY - 8, point.originY + 8);

      // update the point's current coordinates
      point.x = x;
      point.y = y;

      // progress the point's x, y values through "time"
      point.noiseOffsetX += this.noiseStep;
      point.noiseOffsetY += this.noiseStep;
    }

    // non performant, we need one call to request animation frame
    window.requestAnimationFrame(this.animate);
  }
}
