






import { Vue } from 'vue-property-decorator';
import { setProviderEventsHandler } from '@manifoldxyz/manifold-dropsitetools-lib';

export default class App extends Vue {
  created () : void {
    setProviderEventsHandler(this.handleConnection);
  }

  async handleConnection (event: unknown) : Promise<void> {
    console.log(event);
  }
}
