// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#souls-holder {\n  position: absolute;\n  width: 100vw;\n  height: 100%;\n  overflow: hidden;\n  top: -50px;\n  left: -50px;\n  z-index: -102;\n}\n.soul {\n  background: none;\n  position: absolute;\n}\n#home {\n  background-size: 100%;\n  margin: 0 auto;\n  max-width: 1440px;\n  background-repeat: no-repeat;\n  overflow: visible;\n}\n@media screen and (max-width: 1024px) {\n#home {\n    width: 100%;\n}\n}\n#home footer {\n  position: relative;\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 25px;\n  padding: 60px 30px 100px;\n}\n#home footer .exit-cta {\n  width: 100%;\n  display: block;\n  font-weight: 900;\n  text-transform: uppercase;\n  font-size: 20px;\n  max-width: 600px;\n  text-align: center;\n}\n#home footer .exit-links {\n  display: flex;\n  gap: 12px;\n}\n#home footer .exit-links .exit-link {\n  transition: opacity 0.2s ease;\n}\n#home footer .exit-links .exit-link:hover {\n  opacity: 0.7;\n  cursor: pointer;\n}\n.manifold-logo {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-height: 90px;\n  height: auto !important;\n  text-align: center;\n  font-weight: 300;\n  padding: 30px 30px 100px;\n  font-family: \"Raleway\", sans-serif !important;\n  text-transform: uppercase;\n}\n.manifold-logo svg {\n  width: 36px;\n  height: 19px;\n}\n.manifold-logo > span {\n  display: block;\n  margin-top: 10px;\n  color: #999 !important;\n}\n.manifold-logo > span a {\n  display: inline;\n  transition: color 0.2s ease;\n}\n.manifold-logo > span a:hover {\n  color: #999 !important;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
