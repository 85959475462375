var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "blob" }, [
    _c("svg", { attrs: { viewBox: "0 0 200 200" } }, [
      _c(
        "defs",
        [
          _c(
            "radialGradient",
            { attrs: { id: "gradient2" } },
            [
              _c("stop", {
                attrs: {
                  id: "gradientStop3",
                  offset: "10%",
                  "stop-color": "#089CFF",
                },
              }),
              _c("stop", {
                attrs: {
                  id: "gradientStop4 ",
                  offset: "100%",
                  "stop-color": "#1D63F4",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("path", { ref: "path", attrs: { d: "", fill: "url('#gradient2')" } }),
    ]),
    _c("span", [_vm._v(_vm._s(_vm.text))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }