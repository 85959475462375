var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "hero" } }, [
    _c("iframe", {
      staticClass: "soul0",
      attrs: { src: "iframe.html", scrolling: "no" },
    }),
    _vm._m(0),
    _vm._m(1),
    _c("div", { attrs: { id: "hero-intro" } }, [
      _c(
        "div",
        { staticClass: "bubbles" },
        [
          _c("Blob", {
            staticClass: "bubble-1",
            attrs: { text: "10000 Interactive Beings" },
          }),
          _c("Blob2", {
            staticClass: "bubble-2",
            attrs: { text: "Living On Ethereum" },
          }),
          _vm._m(2),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        class: {
          "purchase-dialog-background--visible": _vm.isPurchaseDialogOpen,
          "purchase-dialog-background--confirming": _vm.isConfirmingTransaction,
        },
        attrs: { id: "purchase-dialog-background" },
      },
      [
        _c("div", { attrs: { id: "purchase-dialog-background__inner" } }, [
          _c("div", { attrs: { id: "purchase-dialog-background-image" } }, [
            _c(
              "svg",
              {
                attrs: {
                  viewBox: "0 0 830 648",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M629.793 591.041C508.061 641.961 385.56 640.487 262.291 586.62C139.022 532.753 73.603 430.429 66.0344 279.647C58.4657 128.865 122.848 41.0793 259.183 16.2907C395.517 -8.4979 527.077 -3.27354 653.863 31.9638C780.649 67.2011 838.766 156.46 828.216 299.741C817.666 443.021 751.525 540.122 629.793 591.041Z",
                    fill: "url(#paint0_radial_147_1241)",
                    stroke: _vm.isConfirmingTransaction
                      ? "#F31BA5"
                      : "transparent",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M55.9379 441.182C66.0007 430.729 77.1781 421.714 90.8987 415.777C100.191 411.751 110.12 409.344 121.216 410.303C136.343 411.611 151.108 416.106 165.55 423.274C176.779 428.846 187.106 435.895 196.344 444.746C211.129 458.916 220.472 475.217 223.049 493.924C225.563 512.196 227.279 530.391 226.248 548.219C225.573 559.908 223.361 571.004 218.085 580.799C209.872 596.079 196.387 604.104 177.553 604.697C163.563 605.14 149.517 602.141 135.424 597.784C122.684 593.847 110.166 588.96 97.9982 582.699C87.9128 577.51 78.1893 571.584 69.1465 564.392C61.4289 558.275 54.361 551.557 48.288 543.835C36.4023 528.665 30.5914 512.581 31.644 495.367C32.4886 480.559 37.114 466.876 45.1159 455.516C48.5376 450.595 52.3193 445.951 55.9379 441.182Z",
                    fill: "url(#paint1_radial_147_1241)",
                  },
                }),
                _c(
                  "defs",
                  [
                    _c(
                      "radialGradient",
                      {
                        attrs: {
                          id: "paint0_radial_147_1241",
                          cx: "0",
                          cy: "0",
                          r: "1",
                          gradientUnits: "userSpaceOnUse",
                          gradientTransform:
                            "translate(447.87 297.656) rotate(121.327) scale(438.126 497.053)",
                        },
                      },
                      [
                        _c("stop", {
                          attrs: {
                            "stop-color": _vm.isConfirmingTransaction
                              ? "#000000"
                              : "#F31BA5",
                          },
                        }),
                        _vm._v(" /> "),
                        _c("stop", {
                          attrs: {
                            offset: "1",
                            "stop-color": _vm.isConfirmingTransaction
                              ? "#000000"
                              : "#8261F4",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "radialGradient",
                      {
                        attrs: {
                          id: "paint1_radial_147_1241",
                          cx: "0",
                          cy: "0",
                          r: "1",
                          gradientUnits: "userSpaceOnUse",
                          gradientTransform:
                            "translate(138.712 510.286) rotate(132.51) scale(87.2857 107.712)",
                        },
                      },
                      [
                        _c("stop", { attrs: { "stop-color": "#21D08F" } }),
                        _c("stop", {
                          attrs: { offset: "1", "stop-color": "#1DB6AF" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { attrs: { id: "purchase-dialog-background-countdown" } },
            [
              _c("m-countdown", {
                attrs: {
                  "fallback-provider": _vm.fallbackProvider,
                  network: _vm.network,
                  address: _vm.contractAddress,
                  "client-id": _vm.clientId,
                  "app-name": _vm.appName,
                },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "draggable soul1" }, [
      _c("img", { attrs: { src: require("@/assets/images/soul1.svg") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "draggable soul2" }, [
      _c("img", { attrs: { src: require("@/assets/images/soul2.svg") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "author" }, [
      _vm._v("by Sia &"),
      _c("br"),
      _vm._v("DOR"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }