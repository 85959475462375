var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "sec section-0" }, [
        _c("div", { staticClass: "p" }, [
          _c("span", [
            _vm._v(
              " All SOULS are born with unique colors, voices, & personalities "
            ),
          ]),
        ]),
        _c("div", { staticClass: "souls-group" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/soulsgroup0.svg") },
          }),
        ]),
      ]),
      _c("div", { staticClass: "sec section-1" }, [
        _c("div", { staticClass: "souls-group" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/soulsgroup1.svg") },
          }),
        ]),
        _c("div", { staticClass: "p" }, [
          _c("span", [
            _vm._v(
              " Every SOUL is an interactive lifeform that expresses feelings with sound and movement "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "sec section-0" }, [
        _c("div", { staticClass: "p" }, [
          _c("span", [
            _vm._v(
              " The SOULS community will help Sia create new music AND make an impact with cause projects. "
            ),
          ]),
        ]),
        _c("div", { staticClass: "souls-group" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/soulsgroup4.svg") },
          }),
        ]),
      ]),
      _c("div", { staticClass: "sec section-2" }, [
        _c("div", { staticClass: "souls-group" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/soulsgroup2.svg") },
          }),
        ]),
        _c("div", { staticClass: "p" }, [
          _c("span", [
            _vm._v(
              " SOULS uses A palette of 21 colors. each has up to 4 separate elements. color matches are rare! "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "sec section-3" }, [
        _c("div", { staticClass: "souls-group" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/soulsgroup3.svg") },
          }),
        ]),
        _c("div", { staticClass: "p" }, [
          _c("span", [
            _vm._v(
              " Each SOUL has a unique emotional profile. visit our faq to learn more about the attributes governing each SOUL’S behavior. "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }