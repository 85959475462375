import Vue from 'vue';
import Collectible from '@manifoldxyz/collectible-widget';
import manifoldCSSReset from '@manifoldxyz/css-reset';
import MConnection from '@manifoldxyz/oauth-connect-widget';
import 'tailwindcss/dist/tailwind.css';
import { FALLBACK_PROVIDER, NETWORK } from '@/constants';
import store from '@/store/store';
import App from './App.vue';
import router from './router';

manifoldCSSReset();

const options: any = {
  network: NETWORK,
  fallbackProvider: FALLBACK_PROVIDER
};

Vue.use(MConnection.MultiWalletConnect, options);
Collectible.Countdown.install(Vue);
Collectible.Buy.install(Vue);
Collectible.Inventory.install(Vue);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
