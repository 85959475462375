// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "svg[data-v-2ae82726] {\n  width: 280px;\n  height: 280px;\n  z-index: -1;\n}\npath[data-v-2ae82726] {\n  cursor: pointer;\n}\n.blob[data-v-2ae82726] {\n  position: relative;\n  width: 280px;\n  height: 280px;\n}\n.blob span[data-v-2ae82726] {\n  position: absolute;\n  margin: auto;\n  height: 72px;\n  width: 200px;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  text-align: center;\n  font-weight: 900;\n  font-size: 24px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
