var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { class: { max: _vm.$route.path === "/mint" }, attrs: { id: "header" } },
    [
      _vm.$route.path === "/mint"
        ? _c("TitleGL", {
            staticClass: "logo",
            attrs: {
              glb: "title.glb",
              width: 200,
              height: _vm.titleGLHeight,
              scale: 1.8,
            },
            on: {
              "show-tab": function ($event) {
                return _vm.showTab(0)
              },
            },
          })
        : _c("TitleHTML", {
            staticClass: "logo",
            on: {
              click: function ($event) {
                return _vm.viewYourSoul()
              },
            },
          }),
      _c(
        "div",
        { class: { open: _vm.mobileOpen }, attrs: { id: "header-menu" } },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "group _3" },
            [
              _vm.$route.path === "/mint"
                ? _c(
                    "router-link",
                    {
                      staticClass: "button explore",
                      attrs: { target: "_blank", to: "/explore" },
                    },
                    [_vm._v(" Explore ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "group _4" },
            [
              _c("m-multi-wallet-connect", {
                staticClass: "button",
                attrs: {
                  network: _vm.network,
                  "app-name": "White Lights Main",
                  "client-id":
                    "319f4e02827585361b6aacdafc445fbef9245ddff32db3c22ce4032e2d638597",
                  "fallback-provider": _vm.fallbackProvider,
                  avatar: "",
                  "auto-reconnect": false,
                  "override-connect-text": _vm.isMobile
                    ? "Connect Wallet"
                    : "Connect to view your Souls",
                  "show-chain": true,
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  attrs: { id: "header-mobile-toggle" },
                  on: { click: _vm.toggleMobile },
                },
                [
                  !_vm.mobileOpen
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/images/mobile-open.svg"),
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/images/mobile-close.svg"),
                        },
                      }),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "spacer" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "group _1" }, [
      _c(
        "a",
        {
          staticClass: "exit-link",
          attrs: {
            href: "https://opensea.io/collection/souls",
            target: "_blank",
          },
        },
        [_c("img", { attrs: { src: require("@/assets/images/opensea.png") } })]
      ),
      _c(
        "a",
        {
          staticClass: "exit-link",
          attrs: { target: "_blank", href: "https://twitter.com/souls_galaxy" },
        },
        [_c("img", { attrs: { src: require("@/assets/images/twitter.png") } })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }