// import storageHelper from 'storage-helper';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Mint from '@/views/Mint.vue';
import Protected from '@/views/Protected.vue';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mint',
    name: 'Mint',
    component: Mint
  },
  {
    path: '/protected',
    name: 'Protected',
    component: Protected,
    props: {}
  },
  {
    path: '/explore',
    name: 'Explore',
    component: Home
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

// router.beforeEach((to, from, next) => {
//   if (to && to.meta && to.meta.requiresAuth) {
//     const release = (new Date('2022-06-13T19:15:00+01:00')).getTime();
//     if (!storageHelper.getItem('user-password') && Date.now() < release) {
//       // force them to type in password
//       next('/protected');
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
