var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "explore" },
    [_c("AppHeader"), _vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4)],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "controls", attrs: { id: "audio-btn" } }, [
      _c("div", { attrs: { id: "fullscreen-button" } }, [
        _c("img", {
          staticClass: "btn-img",
          attrs: { src: require("@/assets/images/ic_fs.png") },
        }),
      ]),
      _c("div", { attrs: { id: "sound-button" } }, [
        _c("img", {
          staticClass: "btn-img",
          attrs: {
            id: "sound-toggle-icon",
            src: require("@/assets/images/ic_spk0.png"),
          },
        }),
      ]),
      _c("div", { attrs: { id: "search-button" } }, [
        _c("img", {
          staticClass: "btn-img",
          attrs: { src: require("@/assets/images/magnifying.png") },
        }),
        _c("div", { attrs: { id: "search" } }, [
          _c("input", {
            staticClass: "search-input",
            attrs: {
              name: "search",
              type: "number",
              min: "0",
              max: "9999",
              placeholder: "Find Your Soul By #",
              onkeyup:
                "if(value<0) value=0; if(value>9999) value=9999; if(event.code==='Enter') handleSearchBar(event)",
            },
          }),
        ]),
      ]),
      _c("div", { attrs: { id: "gear-button" } }, [
        _c("img", {
          staticClass: "btn-img",
          attrs: {
            id: "gear-toggle-icon",
            src: require("@/assets/images/gear.png"),
          },
        }),
      ]),
      _c("div", { attrs: { id: "info-button" } }, [
        _c("img", {
          staticClass: "btn-img",
          attrs: { src: require("@/assets/images/question.png") },
        }),
      ]),
      _c(
        "a",
        {
          staticClass: "exit-link",
          attrs: {
            href: "https://opensea.io/collection/souls",
            target: "_blank",
          },
        },
        [_c("img", { attrs: { src: require("@/assets/images/opensea.png") } })]
      ),
      _c(
        "a",
        {
          staticClass: "exit-link",
          attrs: { target: "_blank", href: "https://twitter.com/souls_galaxy" },
        },
        [_c("img", { attrs: { src: require("@/assets/images/twitter.png") } })]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal info" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("h1", [_vm._v("⚙️ Guide ⚙️")]),
        _c(
          "div",
          {
            staticStyle: {
              "text-align": "left",
              "margin-top": "20px",
              "padding-right": "10px",
            },
          },
          [
            _c("strong", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("Interaction"),
            ]),
            _c("br"),
            _vm._v(" • Use the Emoji Menu to view curated SOULS collections"),
            _c("br"),
            _vm._v(
              " • Click & drag SOULS to Interact with them. They will let you know how they feel"
            ),
            _c("br"),
            _vm._v(
              " • Right click a SOUL to Save it (many file types are available)"
            ),
            _c("br"),
            _vm._v(" • Bonus: on iOS, long press a SOUL to open it in AR"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("strong", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("Instrument Mode"),
            ]),
            _c("br"),
            _vm._v(" • Right click in Space for Instrument Controls"),
            _c("br"),
            _vm._v(" • Press 1,2,3,4,5,6,7,8,9 for the Musical Scale"),
            _c("br"),
            _vm._v(" • Press a, e, i, o, u for those Musical Notes"),
            _c("br"),
            _vm._v(" • Press CAPS LOCK for Choir Mode "),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal faq" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("h1", [_vm._v("✨ SOULS ✨")]),
        _c(
          "div",
          {
            staticStyle: {
              "text-align": "left",
              "max-width": "400px",
              "margin-top": "20px",
              "padding-right": "10px",
            },
          },
          [
            _vm._v(
              " Souls are interactive life forms that speak in a musical language sung by SiA. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Souls express themselves through movement and sound. You can click, drag and rearrange them to your heart's content. Some enjoy interaction while others prefer none. They will let you know if they're feeling happy, sad, bored or overstimulated. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " A Soul's physical surface will become fluid and blobby if sad, and angular and spiky if overstimulated (by being clicked on too much). Depending on their mood, they might follow or avoid you. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Souls can Saved as JPG, PNG or GLB files, and used as real world Augmented Reality objects on iOS. "
            ),
            _c("br"),
            _c("br"),
            _c("strong", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("SOULS TRAITS"),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(
              " Traits are detailed in Properties and Levels on OpenSea. Each SOUL is born with its own individual personality, name, voice, color pattern, atmosphere, and rarity. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Right click or tap the ⚙️ to find the keyboard controls so you can play Souls as an instrument. "
            ),
            _c("br"),
            _c("br"),
            _c("strong", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("PROPERTIES"),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(
              " Each Soul is born with its own individual personality, name, voice, color pattern, and rarity. These traits are detailed in Properties and Levels on OpenSea. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " These properties affect their behavior and emotional state: "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Sensitivity = how much interaction is needed to create an emotional response."
            ),
            _c("br"),
            _vm._v(
              " Energy = how frequently that response will be positive or negative."
            ),
            _c("br"),
            _vm._v(" Memory = how long that feeling will last."),
            _c("br"),
            _vm._v(" Limits determines how much interaction is too much! "),
            _c("br"),
            _c("br"),
            _c("strong", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("RARITY"),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(
              " Each Soul is made from a palette of 21 colors and up to 4 colorful elements. Most are born with a matte finish, but rare ones can be shiny, silver or gold. The variation of their voices is linked to the variation of color. For example, Souls with all the same color will sing in the same vowel. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(" Rarity Tiers "),
            _c("br"),
            _c("i", [_vm._v("• Common (7,910)")]),
            _c("br"),
            _c("i", [_vm._v("• Uncommon (1,814)")]),
            _c("br"),
            _c("i", [_vm._v("• Rare (214)")]),
            _c("br"),
            _c("i", [_vm._v("• Epic (51)")]),
            _c("br"),
            _c("i", [_vm._v("• Legendary (11)")]),
            _c("br"),
            _c("br"),
            _c("strong", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("THE ENVIRONMENT"),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(
              " Souls live on Ethereum, which means there are energy costs associated with the project. Souls has partnered with Aerial to purchase carbon credits equivalent to the emissions created by its NFT transactions. These credits support verified environmental efforts. Souls remains committed to making all of its NFT practices carbon-neutral. "
            ),
            _c("br"),
            _c("br"),
            _c("strong", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("TEAM"),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(
              " The SOULS project was created by SiA and David OReilly with development by Manifold, creative code by Vince McKelvie, animation & design by Joel Plosz, and music by Nathan Turczan. "
            ),
            _c("br"),
            _c("br"),
            _c("strong", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("COMMUNICATION"),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(
              " All official communication from the SOULS project will be from its official Twitter and Discord accounts. We will never DM you, ever. "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "emoji-holder" } }, [
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: { onclick: "handleEmojiClick('?rnd=100',0)" },
        },
        [_vm._v("💯")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=101-5&r=2959-11&r=8015-15&r=9455-11&r=727-22&r=2253-20&r=298-5&r=376-5&r=114-5&trunc=50',1)",
          },
        },
        [_vm._v("🧿")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=9957-27&r150-5&r=184-5&r=203-20&r=9102-24&r=9130-39&trunc=50',2)",
          },
        },
        [_vm._v("⚛️")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=5990-5&r=6647-5&r=8419-5&r=3388-5&r=3297-5&r=3443-5&r=4147-5&r=6839-6&r=3824-5&r=5442-2&r=5921-5&r=8374-5&r=3225-12&r=3170-5&r=3412-18&r=5004-20&r=5751-11&r=8251-3&trunc=50',3)",
          },
        },
        [_vm._v("😮")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=251-3&r1015-12&r=1090-12&r=1135-12&r=1205-24&r=1253-12&r=1332-8&r=1645-1&r=1670-1&s=1794&s=1814&r=1702-6&r=1866-2&r=1880-2&s=4951&trunc=50',4)",
          },
        },
        [_vm._v("🌱")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=2295-20&r=6440-10&r=7942-20&r=8651-20&trunc=50',5)",
          },
        },
        [_vm._v("👀")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=1402-6&r=1439-6&r=1468&r=1501-6&r=1516-15&r=1548-3&r=8489-10&r=2780-6&r=2859-5&trunc=50',6)",
          },
        },
        [_vm._v("🐟")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=1961-10&r=1824-20&r=2066-35&r=2042&r=1791-24&r=1931-5&?r=4678-120&?r=4854-100&trunc=50',7)",
          },
        },
        [_vm._v("🐻")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=5366-40&s=5190&r=1320-11&r=2808-5&r=3042-12&r=2888-8&r=7713-3&r=8933-8&r=5140-5&r=5220-6&r=5244-4&r=5339-9&s=7088&s=1914&trunc=50',8)",
          },
        },
        [_vm._v("👻")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=8132-6&r=9001-7&r=8051-2&r=8069-2&s=6958&s=6968&r=6979-2&r=6997-2&r=7030-2&r=7095-2&r=8252-4&r=8266-5&r=8779-12&s=8728&r=8548-4&trunc=50',9)",
          },
        },
        [_vm._v("🤖")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=7547-60&r=7375-30&r=7501-25&r=7310-12&s=7531&r=8340-12&r=8401-5&r=2901-12&r=7698-5&r=8442-3&trunc=50',10)",
          },
        },
        [_vm._v("💥")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=2107-35&r=1564-45&?r=3970-12&r=7100-18&r=3971-40&trunc=50',11)",
          },
        },
        [_vm._v("🦧")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: { onclick: "handleEmojiClick('?r=9665-135&trunc=50',12)" },
        },
        [_vm._v("🌀")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?r=9412-12&r=9345-5&r=9498-70&trunc=50',13)",
          },
        },
        [_vm._v("👽")]
      ),
      _c(
        "div",
        {
          staticClass: "emoji-click",
          attrs: {
            onclick:
              "handleEmojiClick('?s=1111&s=2222&s=3333&s=4444&s=5555&s=6666&s=7777&s=8888&s=2106&s=1812&s=6969&s=1234&s=2001&s=1024&s=1123&s=420&s=6174&s=1729&s=4321&s=2121',14)",
          },
        },
        [_vm._v("🌈")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "frame-holder" } }, [
      _c("iframe", { attrs: { id: "frame", src: "explore-files/app.html" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }