var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "protected" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 offset-md-3" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.validateBeforeSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("label", { staticClass: "custom-label control-label" }, [
              _vm._v("Password"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.password,
                  expression: "password",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control password-field",
              attrs: { type: "text", name: "password" },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value.trim()
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("span", { staticClass: "error help-block" }),
            _vm.error
              ? _c("div", { staticClass: "text-danger" }, [
                  _c("p", [_vm._v("Incorrect password.")]),
                ])
              : _vm._e(),
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v("Submit")]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }