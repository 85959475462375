// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".explore {\n  height: 100%;\n}\n.controls {\n  position: absolute;\n  z-index: 12;\n  width: 40px;\n  height: 30px;\n  top: 20px;\n  right: 15px;\n  cursor: pointer;\n  display: flex;\n  gap: 10px;\n  align-items: flex-end;\n}\n@media (max-width: 1024px) {\n.controls {\n    top: 90px;\n    padding-right: 10px;\n}\n.controls #fullscreen-button {\n    display: none;\n}\n.controls .exit-link {\n    display: none;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
