









































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { EthereumProvider } from '@manifoldxyz/manifold-sdk';
import '../../public/explore-files/scripts/styles.css';
import AppHeader from '@/components/AppHeader.vue';
import { Module1Module } from '@/store/modules/module1';

@Component({
  components: {
    AppHeader
  }
})
export default class Home extends Vue {
  created () : void {
    window.addEventListener(EthereumProvider.ADDRESS_CHANGED, this.detectProvider);
    window.addEventListener('m-authenticated', async (event) => {
      // @ts-ignore
      const client = event.detail.client;
      Module1Module.SetClient(client);
      await this.viewYourSoul();
    });
  }

  detectProvider () : void {
    const selectedAddress = EthereumProvider.selectedAddress();
    const selectedENSName = EthereumProvider.selectedENSName();

    // Check if there is an address and a valid session
    if (selectedAddress) {
      Module1Module.SetWalletAddress(selectedAddress);
      Module1Module.SetEnsName(selectedAddress);
    }
  }

  async viewYourSoul () : Promise<void> {
    if (this.$store.state.module1.walletAddress && this.$store.state.module1.client) {
      // @ts-ignore
      const tokens = await this.$store.state.module1.client.getNFTsOfOwner({
        filters: [
          {
            contractAddress: '0x477F516db22683e65Df58C2cBA4f5D5859d48424'
          }
        ]
      });

      if (tokens.length) {
        const tokenIds: string[] = [];
        tokens.forEach((token: any) => {
          tokenIds.push(token.metadata.name.split('#')[1]);
        });

        let tokenString = '?s=';
        tokenIds.forEach((token: string, index: number) => {
          tokenString += token;

          if (index !== tokenIds.length - 1) {
            tokenString += '&s=';
          }
        });

        // @ts-ignore
        window.handleEmojiClick(tokenString);
      }
    }
  }
}
