import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';

export interface IModule1State {
  walletAddress: string;
  ensName: string;
  client: any;
}

@Module({
  name: 'module1',
  store,
  dynamic: true
})
class Module1 extends VuexModule implements IModule1State {
  public walletAddress: string;
  public ensName: string;
  public client : any;

  // @dev: use the following reference syntax anywhere safely:
  // this.$store.state.module1.walletAddress;

  get isDev () : boolean {
    const searchParams = new URLSearchParams(window.location.search);
    return !!(searchParams.get('dev') && process.env.NODE_ENV === 'development');
  }

  get net () : string {
    return this.isDev ? 'mainnet' : 'testnet';
  }

  @Mutation
  SET_WALLET_ADDRESS (walletAddress: IModule1State['walletAddress']) : void {
    this.walletAddress = walletAddress;
  }

  @Action
  SetWalletAddress (walletAddress: IModule1State['walletAddress']) : void {
    this.SET_WALLET_ADDRESS(walletAddress);
  }

  @Mutation
  SET_ENS_NAME (ensName: IModule1State['ensName']) : void {
    this.ensName = ensName;
  }

  @Action
  SetEnsName (ensName: IModule1State['ensName']) : void {
    this.SET_ENS_NAME(ensName);
  }

  @Mutation
  SET_CLIENT (client: IModule1State['client']) : void {
    this.client = client;
  }

  @Action
  SetClient (client: IModule1State['client']) : void {
    this.SET_CLIENT(client);
  }
}

export const Module1Module = getModule(Module1);
