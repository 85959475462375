var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "content" } }, [
    _c(
      "div",
      { staticClass: "faq-section" },
      [
        _c("Accordion", {
          staticClass: "accordion one",
          attrs: { color: "#f31ba5" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title" }, [
                    _vm._v("What are SOULS?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " SOULS are living artworks created in collaboration between international pop star Sia and renowned visual artist David OReilly. SOULS began as a series of simple colorful paintings that grew over time into an expansive collection of never before seen digital beings built with love and shaped between two friends."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "SOULS exist as virtual objects that can bounce, wiggle, dance, sleep, and even sing. Anyone can save a SOUL, but only 10,000 can have one. "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion two",
          attrs: { color: "#089CFF" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("How much does it cost to mint SOULS?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " The Mint Price for 1 SOUL will be 0.12345 ETH. and there will be a mint limitation of 2 SOULS per Wallet. A percentage of Mint proceeds will be allocated towards cause projects that will be revealed at a later date."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " Additionally, SOULS will be airdropped 100% free to SiA and David OReilly’s day one supporters including all SIAVERSE Discord members with the “SIA SUPER STAN” role (who registered by the deadline). "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion three",
          attrs: { color: "#895DEF" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("How many SOULS exist?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " There are and will only ever be 10,000 SOULS for the 8 billion people on Earth. "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion five",
          attrs: { color: "#F8A011" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("How can I interact with SOULS?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " SOULS express their feelings through movement and sound. Left click to Interact, right click to Save. They will let you know if they're feeling happy, sad, bored or overstimulated."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "SOULS have three voices, consisting of seven notes, each sung by Sia in a range of the vowels A,E, I, O and U. SOULS are made from a palette of twenty one colors with multiple elemental variations. The variation of voices is linked to the variation of color. While most SOULS are born as a Matte pastel some rare SOULS are Shiny, Silver or even Gold. SOULS can be Saved - in multiple formats (JPG, PNG, GLB) and can be bought, sold, and even burned. If that's not enough, on iOS Mobile, each SOUL can be downloaded as an AR filter that interacts directly with your phone's camera and allows users to create content! "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion six",
          attrs: { color: "#FF1138" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("What traits do SOULS have?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _c("ul", [
                      _c("li", [
                        _vm._v("• "),
                        _c("em", [_vm._v("Sensitivity")]),
                        _vm._v(
                          " determines how much interaction is needed to create an emotional reaction."
                        ),
                      ]),
                      _c("li", [
                        _vm._v("• "),
                        _c("em", [_vm._v("Mood Index")]),
                        _vm._v(
                          " determines how frequently that reaction will be interpreted as a positive or negative feeling."
                        ),
                      ]),
                      _c("li", [
                        _vm._v("• "),
                        _c("em", [_vm._v("Emotion Decay")]),
                        _vm._v(" determines how long that feeling will last."),
                      ]),
                      _c("li", [
                        _vm._v("• "),
                        _c("em", [_vm._v("Overstimulation Threshold")]),
                        _vm._v(
                          " determines when too much interaction is too much! SOULS voices will raise an octave when they are in an emotional state."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "• If left alone, SOULS can enter an emotional state with no interaction, and this state will be determined by Mood Index. A soul's physical surface will become fluid and blobby if sad, and angular and spiky if overstimulated. They will enter a follow state if happy or content, and an avoid state if sad or overstimulated."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "• SOULS are very low maintenance, but they will cry out for attention unless you give them a Sound Bath. Each SOUL has a unique sonic frequency that can be turned on by clicking the music icon in the SOULS Galaxy (to be revealed on this site once mint is complete)."
                        ),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion seven",
          attrs: { color: "#f31ba5" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("What is the SOULS Galaxy?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " The SOULS Galaxy is a micro-metaverse that will be revealed on this site once mint is complete. It will be the place to play with your SOUL and others. "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion eight",
          attrs: { color: "#089CFF" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("What is the ROADMAP for the SOULS community?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " Beyond the artwork itself SOULS will reward holders in many ways. Post mint plans will begin to be revealed as the project rolls out. Here’s a quick roadmap sneak peek for now – the SOULS community will help Sia create new music AND make an impact with cause projects. "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion nine",
          attrs: { color: "#895DEF" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("How do I get access to the SOULS allowlist?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " All details regarding mint will be revealed as the project rolls out but will be 100% Allowlist Raffle based. No grinding. A very small # of high-rarity SOULS will be auctioned off just ahead of the mint. 200 SOULS will be reserved for the Souls treasury for various uses. You can register for the Allowlist Raffle with our partners at PREMINT "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.premint.xyz/souls/",
                          target: "_blank",
                        },
                      },
                      [_vm._v("here")]
                    ),
                    _vm._v(
                      " and after you've registered you can check your registration status "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.premint.xyz/souls/verify/",
                          target: "_blank",
                        },
                      },
                      [_vm._v("here")]
                    ),
                    _vm._v(". "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion ten",
          attrs: { color: "#00B3A9" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("How do secondary royalties work for SOULS?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " SOULS will have creator royalties on secondary markets like OpenSea and LooksRare. A percentage of the royalties from primary and secondary sales will be given to the community treasury that will be working on cause-based projects post-mint. These percentages will be revealed closer to mint. "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion eleven",
          attrs: { color: "#F8A011" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("Are SOULS carbon neutral?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " YES! Though SOULS are minted on Ethereum (which means there are energy costs associated with that process), the blockchain industry is making strides to improve energy efficiency. In order to make its blockchain transactions carbon-neural, SOULS has partnered with Aerial to purchase carbon credits equivalent to the emissions created by its NFT transactions. These credits support verified environmental efforts. SOULS remains committed to making all of its NFT practices carbon-neutral. For more information on Aerial’s process and our commitment to the planet click "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.aerial.is/",
                          target: "_blank",
                        },
                      },
                      [_vm._v("here")]
                    ),
                    _vm._v(". "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion twelve",
          attrs: { color: "#FF1138" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("Who is the team behind the SOULS project?"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " The SOULS project was created by Sia and David OReilly with development by Manifold, creative code by Vince McKelvie, animation & design by Joel Plosz, and music by Nathan Turczan. "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("Accordion", {
          staticClass: "accordion thirteen",
          attrs: { color: "#f31ba5" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", { staticClass: "accordion-title one" }, [
                    _vm._v("Communication"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "accordian-paragraph" }, [
                    _vm._v(
                      " All official communication from the SOULS project will be from the official Twitter account ("
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://twitter.com/souls_galaxy",
                          target: "_blank",
                        },
                      },
                      [_vm._v("here")]
                    ),
                    _vm._v(
                      ") and the #announcement channel in the official Discord ("
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://discord.gg/siaverse",
                          target: "_blank",
                        },
                      },
                      [_vm._v("here")]
                    ),
                    _vm._v("). We will never DM you, ever. "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }