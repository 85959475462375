
























import storageHelper from 'storage-helper';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Protected extends Vue {
  error: boolean|null = null;
  password: string|null = null;

  validateBeforeSubmit () : void {
    if (this.password === 'manifoldcrush1234') {
      this.error = false;
      storageHelper.setItem('user-password', this.password);
      this.$router.replace('/');
    } else {
      this.error = true;
    }
  }
}
