



















































































































































import jQuery from 'jquery';
import { Component, Vue, Watch } from 'vue-property-decorator';
import 'jqueryui'; // do not delete this otherwise draggable() wont exist
import Blob from '@/components/Home/Blob.vue';
import Blob2 from '@/components/Home/Blob2.vue';
import { ACTUAL_CONTRACT_ADDRESS,
  APP_NAME,
  CLIENT_ID,
  CONTRACT_ADDRESS,
  FALLBACK_PROVIDER,
  NETWORK } from '@/constants';

// override fetch for patch with diff minting contract vs actual extension vs core creator
// const { fetch: originalFetch } = window;
// window.fetch = async (...args) => {
//   // @ts-ignore
//   let [resource, config] = args;
//   let response = new Response();
//   if (typeof resource !== 'string' && resource.url) {
//     resource = resource.url as string;
//   }

//   // @ts-ignore
//   const lowerResource = resource.toLowerCase();

//   // @ts-ignore
//   if (lowerResource.includes(`/collectible/${CONTRACT_ADDRESS}/token/${ACTUAL_CONTRACT_ADDRESS}`)) {
//     const newResource = (lowerResource as string).replaceAll(CONTRACT_ADDRESS, ACTUAL_CONTRACT_ADDRESS);
//     response = await originalFetch(newResource, config);
//   } else {
//     response = await originalFetch(resource, config);
//   }

//   // manifoldxyz.dev
//   // response interceptor here
//   return response;
// };

export enum MintCountdownType {
  LOADING = 'loading',
  DEACTIVATED = 'deactivated',
  EXPIRED = 'expired',

  TO_CLAIM = 'to-claim',
  CLAIM = 'claim',

  TO_PRESALE = 'to-presale',
  TO_PUBLIC_SALE = 'to-public-sale',

  PRESALE = 'presale',
  PUBLIC_SALE = 'public-sale',
}

@Component({
  components: {
    Blob,
    Blob2
  }
})
export default class Hero extends Vue {
  fallbackProvider = FALLBACK_PROVIDER;
  contractAddress = CONTRACT_ADDRESS;
  network = NETWORK;
  appName = APP_NAME;
  clientId = CLIENT_ID;
  isPurchaseDialogOpen = false;
  isConfirmingTransaction = false;
  mutationObserver?: MutationObserver;
  waitlistDate = new Date('Jul 13 2022 12:15:00 EDT');
  injectorInterval: number | undefined = undefined;
  phase: MintCountdownType | undefined = undefined;

  mounted (): void {
    // idk how to get typescript bindings for jqueryui so:
    jQuery('.draggable').draggable({
      containment: jQuery('#app')
    });

    this.observePurchaseDialogState();

    window.addEventListener('m-collectible-data', (e: any) => {
      this.phase = e.detail.currentSalePhase;
      this.manipulateCountdownTitleText();
    });
  }

  @Watch('phase')
  @Watch('isPurchaseDialogOpen')
  manipulateCountdownTitleText (): void {
    const countdownTitles = document.querySelectorAll('.m-countdown-title');

    if (!countdownTitles.length) {
      return;
    }

    countdownTitles.forEach((x): void => {
      if (this.phase === MintCountdownType.TO_CLAIM) {
        x.innerHTML = 'Claim starts';
        // disable
        const btn = document.querySelector('.m-buy-button') as HTMLElement;
        if (btn) {
          btn.setAttribute('disabled', 'true');
        }
      } else if (this.phase === MintCountdownType.CLAIM) {
        x.innerHTML = 'Claim ends';
        // re-enable button
        const btn = document.querySelector('.m-buy-button') as HTMLElement;
        if (btn) {
          btn.removeAttribute('disabled');
        }
      } else if (this.phase === MintCountdownType.TO_PRESALE) {
        x.innerHTML = 'Waitlist starts'; // edited
        // disable
        const btn = document.querySelector('.m-buy-button') as HTMLElement;
        if (btn) {
          btn.setAttribute('disabled', 'true');
        }
      } else if (this.phase === MintCountdownType.TO_PUBLIC_SALE) {
        x.innerHTML = 'Sale starts';
        // re-enable button
        const btn = document.querySelector('.m-buy-button') as HTMLElement;
        if (btn) {
          btn.removeAttribute('disabled');
        }
      } else if (this.phase === MintCountdownType.PRESALE) {
        x.innerHTML = 'Waitlist ends'; // edited
        // re-enable button
        const btn = document.querySelector('.m-buy-button') as HTMLElement;
        if (btn) {
          btn.removeAttribute('disabled');
        }
      } else if (this.phase === MintCountdownType.PUBLIC_SALE) {
        x.innerHTML = 'Sale ends';
        // re-enable button
        const btn = document.querySelector('.m-buy-button') as HTMLElement;
        if (btn) {
          btn.removeAttribute('disabled');
        }
      } else if (this.phase === MintCountdownType.EXPIRED) {
        x.innerHTML = 'Sale ended';
        // disable
        const btn = document.querySelector('.m-buy-button') as HTMLElement;
        if (btn) {
          btn.setAttribute('disabled', 'true');
        }
      } else if (this.phase === MintCountdownType.DEACTIVATED) {
        x.innerHTML = 'Sale Not Active';
        // disable
        const btn = document.querySelector('.m-buy-button') as HTMLElement;
        if (btn) {
          btn.setAttribute('disabled', 'true');
        }
      } else {
        x.innerHTML = 'Loading';
      }
    });
  }

  // This is sadly the only way I could figure out to derive whether the purchase dialog is open and if we are transacting.
  observePurchaseDialogState (): void {
    const callback = (mutationList: MutationRecord[]) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((target) => {
            const classList = (target as HTMLElement).classList
              ? Array.from((target as HTMLElement).classList)
              : [];

            if (classList.includes('m-collectible')) {
              this.isPurchaseDialogOpen = true;

              mutationObserver.observe(target, {
                childList: true,
                subtree: true
              });
            }

            if (classList.includes('m-confirmation-dialog')) {
              this.isConfirmingTransaction = true;
            }
          });

          mutation.removedNodes.forEach((target) => {
            const classList = (target as HTMLElement).classList
              ? Array.from((target as HTMLElement).classList)
              : [];

            if (classList.includes('m-collectible')) {
              this.isPurchaseDialogOpen = false;
              this.isConfirmingTransaction = false;
            }

            if (
              classList.includes('m-confirmation-dialog') ||
              classList.includes('m-minted')
            ) {
              this.isConfirmingTransaction = false;
            }
          });
        }
      });
    };

    const mutationObserver = new MutationObserver(callback);

    mutationObserver.observe(document.body, {
      childList: true
    });
  }

  destroyed (): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }
}
