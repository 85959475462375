






































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Accordion extends Vue {
  @Prop({
    type: String,
    required: true,
    default: 'white'
  }) color: string;

  isOpen = false;

  toggleAccordion () : void {
    this.isOpen = !this.isOpen;
  }
}
